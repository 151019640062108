import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import ContentWrapper from "styles/contentWrapper"
import Hero from "components/sections/page404/hero"
import Layers from "components/sections/layers"
const StyledSection = styled.section`
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  h1 {
    font-size: 1.5rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    max-width: 36rem;
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const PageNotFound = ({ data }) => {
  
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout footerContent={data.footer.edges} headerContent={data.header.edges} page="404">
        <SEO
          frontmatter={
            {title: "404: Not found", description: "404: Not found"}
          }
          meta={[{ name: "robots", content: "noindex" }]}
        />
        {/*  <Hero content={data.hero.edges} /> */}
        <Hero />
        <Layers content={data.layers.edges} />
      </Layout>
    </GlobalStateProvider>
  )
}

export default PageNotFound

export const pageQuery = graphql`
  {
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/index/hero/" } }) {
      edges {
        node {
          frontmatter {
            greetings
            title
            subtitlePrefix
            subtitle
          }
        }
      }
    }
    layers: allMdx(filter: { fileAbsolutePath: { regex: "/index/layers/" } }) {
      edges {
        node {
          exports {
            shownItems
            items {
              title
              subtitle
              text
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
          frontmatter {
            label
            title1
            title2
            textLeft
            textRight
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
