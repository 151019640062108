import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { Link } from "gatsby"
import notFoundImage from "content/images/404.png"
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import Underlining from "styles/underlining"
import SplashScreen from "components/splashScreen"
import { lightTheme, darkTheme } from "styles/theme"
import { isSSR } from "utils/"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
/*   background-image: url("https://hrflow-ai.imgix.net/backgrounds/main-spot.svg"),
  url("https://hrflow-ai.imgix.net/gradient-bg.webp"); */
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 29rem 25rem, cover;
  background-position: 68% 0, center;
  padding-bottom: 3.5rem;
  padding-top: 7.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 0;
    padding-top: 7.5rem;
  }

`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .illustration {
      margin-top: 2rem;
      padding-left: 3rem;
      background-image: url(https://hrflow-ai.imgix.net/backgrounds/secondary-spot-big-right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      img {
        width: 100%;
        height: auto;
        opacity: 0.6;
      }
    }

    .intro {
      text-align: center;
      .title {
        margin-bottom: 1rem;
      }
      .subtitle {
        padding: 0;
        font-weight: 300;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        text-align: left;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      align-items: center;
      .illustration {
        margin-top: 0;
      }
    }
  }
`


const AnimatedUnderlining = motion.custom(Underlining)

const Hero = () => {
  const { isIntroDone, darkMode } = useContext(Context).state
  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const sControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      await gControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1 },
      })
      await sControls.start({
        opacity: 1,
        x: 0,
      })
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, gControls, sControls])
  

  const openIntercomModal = () => {
    if(!isSSR) {
      Intercom('showNewMessage');
    }
  }
  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <div className="intro">
          <motion.div
            initial={{ opacity: 1, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <h1 className="title">
              Page not found!
            </h1>
            <h2 className="subtitle">
              Sorry, but the page you were looking for could not be found.
            </h2>
            <p>
              You can <Link to="/">return to our home page</Link>, or <a href="#" onClick={openIntercomModal}>drop us a line</a> if you can't find what you're looking for.
            </p>
          </motion.div>
        </div>
        <div className="illustration">
          <img alr="404 not found" src={notFoundImage} />
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Hero
